import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <main>
        <h1>We're sorry</h1>
        <p>We couldn't find the page you are looking for.</p>
        <p><Link to="/">Click here</Link> to return to the home page.</p>
      </main>
    </Layout>
  )
}

export default NotFoundPage
